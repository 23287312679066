const selects = document.getElementsByClassName("js-search-select");

const getContent = (select) => {
  for (const elem of select) {
    if (elem.selected) {
      return elem.text;
    }
  }
  return false;
};

const reset = (elem, input) => {
  const selectElem = elem;
  const inputElement = input;
  selectElem.setAttribute("size", 1);
  selectElem.style.top = 0;
  selectElem.style.opacity = 0;
  selectElem.style.zIndex = "-1";
  inputElement.value = getContent(selectElem);
};

for (const select of selects) {
  const selectId = select.id;
  const inputElement = document.createElement("input");
  inputElement.classList.add("form-control");
  inputElement.classList.add("required");
  inputElement.classList.add("selectbox-search");
  inputElement.setAttribute("data-select-search", selectId);
  select.parentNode.insertBefore(inputElement, select.nextSibling);
  inputElement.value = getContent(select);

  inputElement.addEventListener("keyup", () => {
    const options = select.children;
    const searchVal = inputElement.value.toLowerCase();
    if (searchVal && searchVal.length >= 1) {
      for (const option of options) {
        if (!option.text.toLowerCase().match(searchVal)) {
          option.style.display = "none";
          option.disabled = true;
        } else {
          option.style.display = "block";
          option.disabled = false;
        }
      }
    } else {
      for (const option of options) {
        option.style.display = "block";
        option.disabled = false;
      }
      select.setAttribute("size", select.children.length);
    }
  });

  inputElement.addEventListener("focus", () => {
    select.setAttribute("size", select.children.length);
    select.style.opacity = 1;
    select.style.top = `${inputElement.offsetHeight * 2}px`;
    select.style.zIndex = 3;
  });

  inputElement.addEventListener("keydown", (key) => {
    switch (key.keyCode) {
      case 40: {
        for (const elem of select) {
          if (elem.selected) {
            const next = elem.nextElementSibling;
            if (next && next.style.display === "block") {
              elem.selected = false;
              next.selected = true;
              break;
            } else if (elem.parentElement) {
              for (const itemParent of elem.parentElement) {
                if (
                  parseInt(elem.value, 10) > parseInt(itemParent.value, 10) &&
                  itemParent.style.display === "block"
                ) {
                  elem.selected = false;
                  itemParent.selected = true;
                  break;
                }
              }
              break;
            } else {
              throw new Error("No se encuentran opciones válidas");
            }
          }
        }
        return false;
      }
      case 38:
        for (const elem of select) {
          if (elem.selected) {
            const prev = elem.previousElementSibling;
            if (prev && prev.style.display === "block") {
              elem.selected = false;
              prev.selected = true;
              break;
            } else if (elem.parentElement) {
              const arrayReverse = Array.prototype.slice.call(elem.parentElement, 0);
              arrayReverse.reverse();
              for (const itemParent of arrayReverse) {
                if (
                  parseInt(elem.value, 10) < parseInt(itemParent.value, 10) &&
                  itemParent.style.display === "block"
                ) {
                  elem.selected = false;
                  itemParent.selected = true;
                  break;
                }
              }
              break;
            } else {
              throw new Error("No se encuentran opciones válidas");
            }
          }
        }
        return false;

      case 13:
        key.preventDefault();
        reset(select, inputElement);
        break;
      case 27:
        key.preventDefault();
        reset(select, inputElement);
        break;
      default:
      // no default
    }
    return false;
  });

  select.addEventListener("blur", (e) => {
    e.preventDefault();
    reset(select, inputElement);
  });

  inputElement.addEventListener("blur", (e) => {
    e.preventDefault();
    setTimeout(() => {
      reset(select, inputElement);
    }, 300);
  });

  select.addEventListener("change", () => {
    reset(select, inputElement);
  });
}
